.header {
    background: linear-gradient(217deg, rgba(0,0,0,.8), rgba(1,0,0,0) 70.71%), linear-gradient(127deg, rgba(113,2,1,.8), rgba(113,2,1,0) 70.71%), linear-gradient(127deg, rgba(255,0,0,.8), rgba(113,2,1,0) 70.71%), linear-gradient(127deg, rgba(113,2,1,.8), rgba(113,2,1,0) 70.71%), linear-gradient(127deg, rgba(113,2,1,.8), rgba(113,2,1,0) 70.71%), linear-gradient(336deg, rgba(0,0,0,.9), rgba(0,0,0,0) 90.71%);
    box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 57px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    z-index: 998;
}

@media (max-width: 680px)
{
    .header {
        height: 50px;
    }
}

@media (max-width: 540px)
{
    ul {
        padding-top: 15px;
        content: "";
    }

    ul li {
        font: 700 Palatino Linotype, Helvetica, Arial !important;
    }    
}

ul li a:hover:after {
    width: 100%;
}

ul li a:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    border-bottom: 2px solid #ed811f;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 0;
}