/* Provide sufficient contrast against white background */
code {
  color: #E01A76;
}

.hostedServices {
    position: absolute;
    bottom: 0;
    left: 0;
}

.hostekImg {
    border: 0;
    border-radius: 6px;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.centerConsole {
    display: flex;
    margin: 0 auto 0 auto;
    height: 100vh;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 80px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

#homeSection h1 {
    font: 400 42.2pt Palatino Linotype, Helvetica, Arial !important;
    color: #ed811f;
    width: auto;
    text-align: left;
    align-content: start;
    display: block;
    margin: 0 0 2rem 0;
    text-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
}

.achievements-container-left div p {
    padding: 0 20px;
}

img {
    image-rendering: pixelated;
}

#homeSection h4 {
    color: hsl(288, 100%, 50%);
}

.myNameControlled {
    justify-content: left;
    align-items: flex-start;
    max-width: 600px;
    width: 100%;
    margin-right: 200px;
    display: flex;
    justify-content: space-around;
    padding: 10px;
}

.centerConsole h1 {
    font-size: 1.8rem;
    margin: 0 0 2rem 0;
    color: #737373;
    z-index: 30;
}

#homeSection p {
    z-index: 20;
}

.reactLogo {
    margin: 0 auto 0 auto;
}

html {
    scroll-behavior: smooth;
}

body {
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    background: linear-gradient(217deg, #ccc 0%, #fdd54a 100%);
}

body::-webkit-scrollbar {
    display: none;
}

.designStaticOne {
    max-height: 600px;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    width: auto;
    background-color: transparent;
    content: "";
}

.socialBanner {
    width: auto;
}

.socialBanner img {
    margin: 0 28px auto;
}

.socialBanner:hover img {
    cursor: pointer;
}

@media (max-width: 980px) {
    .designStaticOne {
        z-index: -10;
        opacity: 40%;
    }

    #homeSection h1 {
        color: hsl(288, 100%, 50%);
    }

    #homeSection h4 {
        color: #ed811f;
        text-shadow: 2px 2px #000;
    }

    .centerConsole {
        width: 100vw;
        height: 90vh;
        justify-content: center;
        padding: 10px;
    }

    .myNameControlled {
        margin: 0;
    }

    .project-container-left, .project-container-middle, .project-container-right,
    .achievements-container-left, .achievements-container-middle, .achievements-container-right {
        margin: 20px !important;
        z-index: 2;
    }

    .achievements-container-left, .achievements-container-middle, .achievements-container-right {
        height: 520px !important;
        width: auto !important;
    }

    .project-container-left, .project-container-middle, .project-container-right {
        height: 500px;
        width: auto !important;
    }

    #projectsSection, #achievementSection, #homeSection, #contactSection {
        width: 100%;
        height: 100vh;
        z-index: 0;
        margin-bottom: 25em;
        margin-top: 10em;
    }

    #projectsSection {
        padding: 75px 0;
    }

    #achievementSection {
        padding-top: 15px;
        transition: transform 0.25s;
        will-change: transform;
    }

    .project-containers, .achievements-container {
        display: flex;
        flex-direction: column !important;
        justify-content: space-between;
        align-items: center;
    }

    .project-container-left h3, .project-container-middle h3, .project-container-right h3,
    #achievementSection h3 {
        font-size: 2.0rem !important;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0;
        margin-inline-end: 0;
    }

    .designStaticTwo {
        flex-direction: column;
    }

    #homeSection .hostedServices {
        visibility: collapse;
    }
}

@media (min-width: 980px) and (max-width: 1180px)
{
    .project-containers, .achievements-container {
        flex-direction: column !important;
        align-items: center;
    }

    .designStaticOne {
        z-index: -10;
        opacity: 40%;
    }

    #homeSection h1 {
        color: hsl(288, 100%, 50%);
    }

    #homeSection h4 {
        color: #ed811f;
        text-shadow: 2px 2px #000;
    }

    .centerConsole {
        width: 100vw;
        height: 90vh;
        justify-content: center;
        padding: 10px;
    }

    .myNameControlled {
        margin: 0;
    }

    .project-container-left, .project-container-middle, .project-container-right,
    .achievements-container-left, .achievements-container-middle, .achievements-container-right {
        margin: 20px !important;
        z-index: 2;
    }

    .achievements-container-left, .achievements-container-middle, .achievements-container-right {
        height: 525px !important;
        width: 90% !important;
    }

    .project-container-left, .project-container-middle, .project-container-right {
        height: 500px;
        width: 90% !important;
    }

    #projectsSection, #achievementSection, #homeSection, #contactSection {
        width: 100%;
        height: 100vh;
        z-index: 0;
        margin-bottom: 25em;
        margin-top: 10em;
    }

    #projectsSection {
        padding: 75px 0;
    }

    #achievementSection {
        padding-top: 15px;
    }

    .project-containers, .achievements-container {
        display: flex;
        flex-direction: column !important;
        justify-content: space-between;
        align-items: center;
    }

    .project-container-left h3, .project-container-middle h3, .project-container-right h3,
    #achievementSection h3 {
        font-size: 2.0rem !important;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0;
        margin-inline-end: 0;
    }

    .designStaticTwo {
        flex-direction: column;
    }
}

@media (min-width: 1180px) and (max-width: 1620px) {
    .project-container-left, .project-container-middle, .project-container-right,
    .achievements-container-left, .achievements-container-middle, .achievements-container-right {
        width: auto !important;
    }

    .project-containers, .achievements-container {
        display: flex !important;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        width: 100vw !important;
    }

    .project-container-left, .project-container-middle, .project-container-right,
    .achievements-container-left, .achievements-container-middle, .achievements-container-right {
        margin: 20px !important;
        z-index: 2;
    }

    .achievements-container-left, .achievements-container-middle, .achievements-container-right {
        height: 525px !important;
        width: auto !important;
    }

    .project-container-left, .project-container-middle, .project-container-right {
        height: 500px;
        width: auto !important;
    }

}

@media (min-width: 1620px) and (max-width: 2020px) {
    .designStaticOne {
        z-index: -5;
        opacity: 60%;
    }
}

@media (min-width: 2080px) and (max-width: 3000px) {
    .designStaticOne {
        z-index: 999;
        opacity: 100%;
    }

    .repoGithub > * {
        z-index: 9990;
    }
}

.centerConsole > p {
    font: bold 14pt Palatino Linotype, Helvetica, Arial !important;
    color: #000;
}

.pageNavbtn {
    background-color: #b5f9e5;
    border-radius: 16px;
    border: 0;
    box-shadow: 0 10px 30px -15px rgb(0 0 0 / 40%);
    max-width: 200px;
    max-height: 62px;
    height: 100%;
    width: 100%;
    color: #000001;
    padding: 20px 20px 45px 20px;
    justify-content: center;
    vertical-align: middle;
    align-content: center;
    display: inline-block;
}

.pageNavbtn a, .pageNavbtn label, .pageNavbtn img {
    cursor: pointer !important;
    color: #000;
    font: bold 14pt Palatino Linotype, Helvetica, Arial !important;
}

.pageNavbtn:hover, .pageNavbtn label a:hover {
    background-color: hsla(24, 100%, 41%);    
    cursor: pointer;
    -webkit-transition: all 1.5s linear;
    -moz-transition: all 1.5s ease;
    -ms-transition: all 1.5s ease;
    -o-transition: all 1.5s ease;
    transition: all 1.5s ease;
}

.pageNavbtn:hover label {
    color: #e6fff8;
}

.pageNavbtn:hover {
    border: 0.5px solid #000;
}

.project-containers, .achievements-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1680px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    vertical-align: middle;
}

#contactSection {
    clear: both;
    position: relative;
}

.project-container-left, .project-container-middle, .project-container-right,
.achievements-container-left, .achievements-container-middle, .achievements-container-right {
    height: 525px;
    width: 600px;
    padding: 0 5px 5px 25px;
    border-radius: 4px;
    margin: 0 10px;
    box-shadow: 0 10px 30px -15px rgb(0 0 0 / 60%);
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    flex-direction: row;
    /* flex-grow and flex-shrink properties are both 0 so
     that the flex layout algorithm does not resize them: */
    flex-grow: 0;
    flex-shrink: 0;
    /* I've removed the 'width' declaration, and replaced it
     with flex-basis: */
    flex-basis: 500px;
    /* as the flex-direction is 'row' the flex-layout will
     take care of the width in that axis, but the cross-
     axis height should be stated: */
    position: relative;
}

#projectsSection h3,
#achievementSection h3 {
    font-size: 2.3rem;
    text-transform: capitalize;
    letter-spacing: -3px;
    line-height: 4rem;
    font-family: Calibre-Medium, sans-serif;
    font-weight: bold;
    text-shadow: 0 15px 15px rgb(0 0 0 / 40%);
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.designStaticTwo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* we use 'gap' here to set the 10px 'gutter' between
     adjacent elements: */
    gap: 10px;
    /* and padding to move the elements 10px from the
     borders of the parent: */
    padding: 10px;
}

.achievements-container ul li {
    line-height: 45px;
    color: #000;
    font: 600 14pt Palatino Linotype, Helvetica, Arial !important;
    margin: 1em 0;
}

.achievements-container-right ul, .achievements-container-left ul {
    list-style: none;
    padding: 10px 20px;
}

.project-container-left {
    background: linear-gradient(217deg, hsl(0, 100%, 50%) 0%, hsl(240, 20%, 80%) 60%, hsl(140, 100%, 70%) 100%);
}

.project-container-middle {
    background: linear-gradient(217deg, #737373 0%, hsl(240, 100%, 90%) 100%);
}

.project-container-right {
    background: linear-gradient(217deg, hsl(195, 100%, 45%) 0%, hsl(240, 19%, 85%) 100%);
}

.achievements-container-left, .achievements-container-middle, .achievements-container-right {
    background: linear-gradient(217deg, hsl(0, 0%, 45%) 0%, hsl(0, 0%, 100%) 75%, hsl(24, 100%, 50%) 100%);
}

.projectLogo:hover {
    width: 36px;
    height: 36px;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    -ms-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
    cursor: pointer;
}

.repoGithub {
    float: right;
    position: relative;
    bottom: 80px;
    right: 35px;
}

.imageShake {
    animation: shake 0.75s forwards;
}

.projectFooter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0; 
    padding-bottom: 20px;
}

.servicesList {
    -moz-column-count: 2; /* For FireFox */
    -webkit-column-count: 2; /* For Safari/Chrome */
    column-count: 2; /* For when the standard gets fully supported */
    position: center;
}

.greyBall:hover {
    transform: scale(2);
    transition: 0.7s;
}

@media (min-width: 320px) and (max-width: 480px) {
    #contactSection .walkerimg {
        width: 220px !important;
        height: 340px !important;
    }

    .hostedServices img {
        width: 50%;
    }

    .hostedServices {
        visibility: collapse;
    }

    #homeSection, #projectsSection, #achievementSection {
        margin: 25px 0 450px 0;
    }

    #projectsSection img {
        width: 28px;
        height: 28px;
        margin-top: 5px;
    }

    #achievementSection .achievements-container .achievements-container-right, #achievementSection .achievements-container .achievements-container-left {
        height: 800px !important;
    }

    .socialBanner {
        width: 100%;
        align-content: center;
        align-items: center;
        vertical-align: middle;
        margin-left: 0 auto 0 auto;
        padding-left: 30px;
    }

    .socialBanner img {
        width: 48px;
        height: 48px;
    }

    #contactSection .hostedServices {
        visibility: visible !important;
    }

    #homeSection {
        height: calc(100% - 60px);
        padding-top: 55px;
    }

    #homeSection .myNameControlled h1 {
        font-size: 2.2em !important;
    }

    #projectsSection, #achievementSection {
        padding-top: 90px;
        height: calc(100% - 60px);
    }

    .project-container-right {
        padding-right: 20px;
    }

    .project-container-right img {
        width: 20px !important;
        height: 20px !important;
    }
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}